/* FooterBar.css */
.footer-bar {
    background-color: #F9F9F9; /* Set the background color */
    color: black;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000; /* Ensures it stays on top */
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5%; /* Responsive padding */
   
    margin: auto;
  }
  
  .footer-logo img {
    max-height: 50px; /* Limits the logo's height to ensure it fits nicely */
    width: auto; /* Maintains aspect ratio */
  }
  
  .footer-info {
    display: flex;
    align-items: center; /* Ensures vertical alignment is centered */
  }
  
  .footer-location, .footer-contact {
    text-align: right;
    margin-left: 20px; /* Adds space between location and contact */
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-info {
      flex-direction: column; /* Stack the location and contact info vertically on small screens */
      align-items: center;
    }
  
    .footer-location, .footer-contact {
      text-align: center;
      margin-left: 0; /* Remove margin between location and contact on small screens */
    }
  
    .footer-logo img {
      max-height: 40px; /* Slightly smaller on smaller screens */
    }
  }
  