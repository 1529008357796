/* HomePage.css */

.home {
    font-family: 'Arial', sans-serif;
    color: white;
    background-color: white;
    line-height: 1.6;
}

.splash-screen {
    background-image: url('../../assets/2.png'); /* Path to your background image */
    background-size: cover; /* Cover the entire area of the splash screen */
    background-position: center; /* Center the background image */
    background-attachment: fixed;
    background-repeat: no-repeat; /* Do not repeat the image */
    height: 90vh; /* Full height of the viewport */
    display: flex;
    align-items: center;
    justify-content: space-around; /* Adjust this as needed */
    padding: 20px;
}

.feature-section {
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
}

.feature-left, .feature-right {
    flex: 1; /* Each side takes up half of the section */
    padding: 20px;
}

.feature-left img {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
}

.feature-right {
    padding-left: 50px;
}

.feature-link {
    display: inline-block;
    background: black;
    color: white;
    padding: 20px 23px;
    border-radius: 35px;
    text-decoration: none;
    font-size: 12px;
    transition: background-color 0.3s;
}

.feature-link:hover {
    background-color: #6a11cb;
}

.feature-right h2 {
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    color: black;
}

.feature-right p {
    font-size: 15px;
    font-weight: 100;
    font-style: normal;
}


.left-content {
    text-align: center;
    color: white;
   
   
    font-size: 25px;
    
    flex-direction: column; /* Stacks items vertically */
}

.left-text {
    font-size: 70px;
    font-family: "Roboto", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.roboto-thin {
   
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}


.left-content button {
    
    background-color: white; /* Modern blue background */
    opacity: .7;
    color: black; /* White text color */
    border: none; /* No border */
    padding: 25px 45px; /* Padding for a larger, more clickable area */
    font-size: 15px;
    border-radius: 45px; /* Rounded corners */
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    outline: none; /* Removes outline */
}

.left-content button:hover {
    opacity: 1;
}

.right-content {
    padding-right: 100px;
}

.right-content img {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
}


.services-section1,
.industry-section,
.explore-section {
    background-color: white;
    color: black;
    padding: 50px 20px;
    text-align: center;
    
}

.services-title {
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    color: black;
}

.services-list, .industries-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    color: black;
    margin-top: 20px;
}

.mid-section {
    padding-top: 50px;
    text-align: center;
}



/* Additional styling and responsive adjustments */
.industry-item, .service-item1 {
    flex-basis: 45%; /* Each item will take up nearly half of the container width, with some padding in between */
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    background: white;
    color: black;
    transition: transform 0.3s ease-in-out;
    display: flex; /* Makes the content flexibly aligned */
    flex-direction: column; /* Stacks content vertically */
    align-items: center; /* Center aligns items for a neat layout */
    text-align: center; /* Centers the text */
}

.service-item1 img {
    width: 100%; /* Full width of the container to maintain consistency */
    height: 400px; /* Fixed height to ensure all images are of the same size */
    object-fit: cover; /* Covers the area, cropping the image if necessary */
    margin-bottom: 2%;
}

.service-item1 {
    flex-basis: 45%; /* Sets the width to almost half of the container with some space */
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    background: white;
    color: black;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the content vertically */
    text-align: center; /* Aligns the text to the center */
}
.service-item1:hover, .industry-item:hover {
    transform: translateY(-5px); /* Adds a hover effect to lift the item */
}

@media (max-width: 768px) {
    .industry-item, .service-item {
        flex-basis: 100%; /* Each item takes full width on smaller screens */
    }
}

@media (max-width: 480px) {

    .splash-screen {
        background-attachment: local;
    }
    .industry-item img, .service-item img {
        height: 150px; /* Smaller fixed height on very small screens */
    }
}

h1, h2, h3 {
    margin: 0 0 20px 0;
}

p {
    margin: 0 0 20px 0;
}

.explore-link {
    display: inline-block;
    background: #2575fc;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.explore-link:hover {
    background-color: #6a11cb;
}

.subheading-main {
    font-size: 23px;
}

/* Smaller screens (e.g., tablets and large phones) */
@media (max-width: 768px) {
    .feature-section {
        flex-direction: column; /* Stack the elements vertically */
    }
    .feature-left,
    .feature-right {
        padding: 10px;
        flex: none; /* Take full width */
    }
    .feature-right {
        padding-top: 20px;
    }
}

/* Very small screens (e.g., small smartphones) */
@media (max-width: 480px) {
    .left-text {
        font-size: 40px;
        font-family: "Roboto", sans-serif;
        font-weight: 200;
        font-style: normal;
    }

    .left-content,
    .right-content {
        padding: 10px; /* Smaller padding */
    }
    .left-content button {
        padding: 20px 20px; /* Smaller button */
    }
    .feature-section,
    .services-section,
    .industry-section,
    .explore-section {
        padding: 20px 10px; /* Smaller padding around sections */
    }
    .service-item,
    .industry-item {
        flex-basis: 100%; /* Each item takes full width */
    }
    h1, h2, h3 {
        font-size: smaller; /* Smaller headings */
    }
    p {
        font-size: smaller; /* Smaller paragraph text */
    }
    .feature-right p,
    .roboto-thin {
        font-size: 15px; /* Adjust font size for readability */
    }
}

/* Ensure images scale correctly */
img {
    max-width: 100%; /* Prevents images from being larger than their container */
    height: auto; /* Maintain aspect ratio */
}

.services-section {
    background-color: white; /* Dark background */
    color: black;
    text-align: center;
    padding: 50px 20px;
}

.services-title {
    font-size: 50px;
    margin-bottom: 40px;
}

.service-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.service-item {
    width: 45%; /* Adjust based on your preference */
    margin: 20px;
    text-align: center;
}

.service-item img {
    width: 100%;
    height: auto;
}

.service-item h3 {
    margin-top: 20px; /* Space between image and heading */
    font-size: 24px;
}

.service-item p {
    margin-top: 10px;
    font-size: 16px;
    line-height: 1.4;
}

@media (max-width: 768px) {
    .service-container {
        flex-direction: column; /* Stack services vertically on small screens */
    }

    .service-item {
        width: 100%;
    }
}