/* BigDataAIPage.css */

.big-data-ai {
    font-family: 'Arial', sans-serif;
    color: white;
    display: flex;
    flex-direction: column; /* Stacks children vertically */
    align-items: center; /* Centers children horizontally */
    overflow: hidden;
}

.heading {
    font-size: 36px;
    margin-bottom: 20px;
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    color: white;
    color: white;
}

.subheading {
    color: white;
    font-size: 18px;
}


.splash3 {
    background-image: url('../../assets/bid.png');
    background-size: cover; /* Cover the entire area of the splash screen */
    background-position: center; /* Center the background image */
    background-attachment: fixed;
    color: white;
    display: flex;
    text-align: left;
    align-items: center; /* Centers items vertically */
    justify-content: space-between; /* Distributes space between text and image */
    width: 100%;
    height: 500px;

}

.splash-content button {
    
    background-color: white; /* Modern blue background */
    opacity: .9;
    color: black; /* White text color */
    border: none; /* No border */
    padding: 15px 25px; /* Padding for a larger, more clickable area */
    font-size: 15px;
    border-radius: 25px; /* Rounded corners */
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    outline: none; /* Removes outline */
}

.splash-content {
    width: 100%;
    padding: 10%;
}




.description-section {
    color: black;
    display: flex;
    padding: 80px;
    align-items: center; /* Ensures vertical alignment is centered */
    justify-content: space-between; /* Distributes space equally between text and image */
    width: 80%; /* Uses full available width */
    flex-direction: row; /* Explicitly set to row for clarity */
    font-family: "Roboto", sans-serif;
}

.text, .image {
    flex: 1; /* Gives equal flex basis to both the text and image containers */
    padding: 0 50px; /* Adds horizontal padding to both, ensuring space between them */
    font-family: "Roboto", sans-serif;
}

.image img {
    width: 100%; /* Image will fill the container */
    height: 300px;
    padding: 10px; /* Adds padding around the image itself */
}

/* Existing styles... */

@media (max-width: 768px) {
    /* Media queries for smaller devices */
    .description-section {
        flex-direction: column; /* Stack image and text vertically */
        align-items: center; /* Center-align items for better visual alignment on smaller screens */
        padding: 40px; /* Adjust padding for smaller screen */
    }
    
    .text, .image {
        padding: 0 20px; /* Adjust padding to reduce space on smaller screens */
        width: 100%; /* Full width for both text and image */
    }
    
    .image {
        order: -1; /* Place image above the text */
    }
}
/* BigDataAIPage.css */
.features-section {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F9F9F9;
    color: black;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
}

.features-section h2{
    font-size: 38px;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    color: black;
}

.feature-grid-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;  /* Ensures there's always horizontal padding */
    box-sizing: border-box; /* Ensures padding is included in the width calculation */
}



.feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
    width: 100%;
    max-width: 1200px; /* Controls maximum width to avoid overly wide grids */
    padding: 20px; /* Ensures there's padding inside the grid */
    box-sizing: border-box; /* Ensures padding does not add to the width */
}

.feature-item {
    background-color: white;
    padding: 50px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.feature-item:hover {
    transform: scale(1.05);
}

/* Media query for very small screens */
@media (max-width: 480px) {
    .splash3 {
        background-attachment: local;
    }
    .feature-grid-container {
        padding: 0 10px; /* Reduce padding on very small screens if necessary */
    }

    .feature-grid {
        padding: 10px; /* Adjust grid padding for small screens */
    }
}



.products-section {
    padding: 20px;
    text-align: center;
}

.products {
    display: flex;
    justify-content: space-around;
}

.product {
    width: 30%;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {

    .splash-image,
    .image {
        width: 100%; /* Adjusts width for full coverage */
        order: 1; /* Image above text */
    }

    .splash-image img {
        width: 100%; /* Image fills its container */
        height: auto; /* Maintains aspect ratio */
        margin: 0 auto; /* Center the image */
        display: block; /* Ensures image behaves as a block element */
    }
}


@media (max-width: 480px) {
    .splash-image img,
    .image img {
        width: 90%;
    }
    .feature-grid-container,
    .feature-grid {
        padding: 0;
    }
    .products {
        flex-direction: column;
        align-items: center;
    }
    .product {
        width: 80%;
        margin-bottom: 20px;
    }
    .splash-content {
        width: 100%; /* Explicitly set width to 100% */
    }
}

