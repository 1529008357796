.splash-main-img {
    background-image: url('../../assets/2.png');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.splash-main-img h1 {
    font-size: 36px;
    margin-bottom: 20px;
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    color: white;
}