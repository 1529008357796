.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    padding: 10px 20px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: background-color 0.3s;
    box-sizing: border-box;
    background-color: white;
}

.logo img {
    height: 40px;
    width: auto;
}

.logo {
    font-size: 20px;
}

.menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
}

.menu-item {
    position: relative;
    padding: 10px 15px;
    font-size: 14px;
}

.menu-item a {
    color: black;
    text-decoration: none;
    display: inline-block;
    background-color: transparent;
}

.submenu {
    list-style: none;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    background-color: white;
    z-index: 1000;
    padding: 5px;
    border-radius: 5px;
    width: calc(100% + 60px);
}

.menu-item:hover .submenu {
    display: block;
    opacity: 1;
    visibility: visible;
}

.submenu li a {
    padding: 10px 20px;
    display: block;
    color: black;
    text-align: left;
    font-size: 13px;
    background-color: transparent;
    margin: 5px;
    border-radius: 5px;
}

.submenu li a:hover {
    background-color: #f1eeee;
}

.menu-icon {
    display: none;
    cursor: pointer;
    font-size: 24px;
}

@media (max-width: 768px) {
    .menu {
        display: none;
        right: -100%; /* Start off-screen to the right */
        top: 0;
        height: 100%;
        width: 250px; /* Adjust width as needed */
        background-color: white;
        flex-direction: column;
        transition: right 0.3s; /* Ensures smooth slide-in */
        padding: 20px 10px;
        padding-top: 50px;
        box-sizing: border-box;
        text-align: right;
        z-index: 1000;
        font-size: 20px;
    }

    .menu.open {
        display: block;
        right: 0; /* Slide in */
    }

    .menu-item {
        padding-top: 20px;
    }

    .menu-icon {
        display: block;
        position: fixed; /* Ensure it doesn't move with scrolling */
        top: 20px; /* Adjust based on your design */
        right: 20px; /* Position on the right */
        z-index: 1001; /* Higher than the menu itself to ensure visibility */
    }
}
