.about-us {
    font-family: 'Arial', sans-serif;
    font-weight: 300;
    color: #333;
    line-height: 1.6;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    min-height: 100vh; 
    overflow: hidden; 
}

.intro-section, .who-we-are-section {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%; /* Adjust based on your design preference */
    max-width: 1200px; /* Maximum width to ensure it doesn't get too wide on large screens */
}

.intro-section .left, .who-we-are-section .left,
.intro-section .right, .who-we-are-section .right {
    width: 50%;
    padding: 20px;
    font-size: 13px;
}

.intro-section .left {
    margin-right: 5%;
}

.intro-section .left h1{ 
    font-weight: 300;
}

.intro-section .right img, .who-we-are-section .right img {
    width: 100%;
    padding: 20px;
    aspect-ratio: 1.2 / 1; /* New property for square aspect ratio */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    max-width: 500px; /* New property to limit maximum width */
}

.who-we-are-section h2 {
    font-size: 40px;
    font-weight: 300;
    color: black;
}

.who-we-are-section .right p {
    text-align: left;
    font-size: 13px;
}

.cta-section {
    background-size: cover;
    background-position: center;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
}

.cta-section h2 {
    font-size: 36px;
    margin-bottom: 20px;
    font-size: 50px;
    font-family: "Roboto", sans-serif;
    font-weight: 350;
    font-style: normal;
    color: white;
}

.cta-section a {
    font-size: 13px;
    padding: 20px 40px;
    background-color: white;
    opacity: .9;
    color: black;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
}

.cta-section button:hover {
    background-color: white;
    opacity: 1;
}

@media (max-width: 768px) {
    .intro-section, .who-we-are-section {
        flex-direction: column;
    }

    .intro-section .left, .who-we-are-section .left,
    .intro-section .right, .who-we-are-section .right {
        width: 100%;
    }
}
