.contact-form {
    margin: auto;
    padding: 20px; /* Uniform padding */
    color: black;
    display: flex;
    gap: 30px; /* Adds space between left and right sections */
    max-width: 1200px; /* Limits maximum width for better control on larger screens */
}

.left, .right {
    flex: 1; /* Ensures both sections flex according to their content size */
    padding: 40px; /* More padding for a spacious layout */
}

.left h1 {
    font-size: 36px; /* Reduced size for more elegance */
    margin-bottom: 10px; /* Space between the heading and text */
}

.left p {
    font-size: 18px;
    color: #555; /* Softer color for the text */
}

label {
    display: block;
    margin-bottom: 20px; /* Adds space below each label */
    color: #333; /* Darker color for better readability */
    font-weight: 100; /* Makes labels bold */
    font-size: 12px;

}

label span { /* Specific style for the (required) text */
    font-weight: thin;
    color: black;
    font-size: smaller;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    border-radius: 8px; /* Subtle rounded corners */
    border: 1px solid #ccc; /* Slightly thicker border for emphasis */
    transition: border-color 0.3s; /* Smooth transition for border color */
}

.contact-form input:focus, .contact-form textarea:focus {
}

.contact-form button {
    background-color: black; /* Consistent theme color for buttons */
    color: white;
    border-radius: 40px;
    padding: 12px 24px;
    font-size: 16px;
    border: 0px solid #ccc;
    transition: background-color 0.3s; /* Smooth background color transition */
    outline: none; /* Removes outline to keep style clean */
}

.contact-form button:hover {
    background-color: black; /* Darker on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .contact-form {
        flex-direction: column;
    }

    .left, .right {
        width: 85%;
        padding: 20px;
    }
}
